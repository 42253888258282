import React, { ReactElement, useEffect, useState } from 'react';
import {
  RiCalendarLine,
} from 'react-icons/ri';
import { meService } from '../../services';
import { getMonthName } from '../../utils/calendarFormatters';

import {
  Container,
  Calendar,
  UserInfos,
  UserImage,
} from './styles';

const Navbar = (): ReactElement => {
  const [username, setUsername] = useState<string>('');
  const today = new Date();

  useEffect(() => {
    getMeInformations();
  }, []);

  async function getMeInformations(): Promise<void> {
    const informationResponse = await meService.get();
    setUsername(informationResponse?.name);
  }

  return (
    <Container>
      <Calendar>
        <RiCalendarLine />
        {getMonthName(today)}
        {' '}
        {today.getDate()}
        ,
        {' '}
        {today.getFullYear()}
      </Calendar>

      <UserInfos>
        Olá,
        {' '}
        <b>{username}</b>
        <UserImage>
          {username?.charAt(0)}
        </UserImage>
      </UserInfos>
    </Container>
  );
};

export default Navbar;
