import React, {
  createContext, useState, useEffect, useContext,
} from 'react';
import api from '../services/api';

type Props = { children: React.ReactNode };

interface UserTypes {
  identifier: string;
  name?: string;
  password?: string;
}

interface AuthContextData {
  signed: boolean;
  user: UserTypes | null;
  Login(userData: UserTypes): Promise<void>;
  Logout(): void;
}

const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: Props): any => {
  const [user, setUser] = useState<UserTypes | null>(null);

  useEffect(() => {
    const storagedUser = sessionStorage.getItem('@App:user');
    const storagedToken = sessionStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.common.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  async function Login(userData: UserTypes): Promise<void> {
    delete api.defaults.headers.common.Authorization;

    try {
      const signInResponse = (await api.post('auth/local', {
        ...userData,
      })).data as any;

      setUser(signInResponse?.user);

      api.defaults.headers.common.Authorization = `Bearer ${signInResponse?.jwt}`;

      sessionStorage.setItem('@App:user', JSON.stringify(signInResponse?.user));
      sessionStorage.setItem('@App:token', signInResponse?.jwt);
    } catch {
      throw new Error('Invalid credentials to Login!');
    }
  }

  function Logout(): void {
    setUser(null);

    sessionStorage.removeItem('@App:user');
    sessionStorage.removeItem('@App:token');

    delete api.defaults.headers.common.Authorization;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user), user, Login, Logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const authContext = useContext(AuthContext);

  return authContext;
}

export default AuthContext;
