import moment from 'moment';

const monthNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];
const dayOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

function getDaysBetweenDates(startDate: any, endDate: any): any[] {
  const now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('DD/MM/YYYY'));
    now.add(1, 'days');
  }

  return dates;
}

function getMonthName(day: Date): string {
  return monthNames[day.getMonth()];
}

function getInterval(): any[] {
  const startDate = moment(new Date());
  const endDate = new Date().setFullYear(new Date().getFullYear() + 1);

  return getDaysBetweenDates(
    startDate,
    new Date(endDate).setMonth(new Date(endDate).getMonth() - 1),
  );
}

export { getMonthName, dayOfWeek, getInterval };
