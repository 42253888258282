import React, { useState } from 'react';

import { useAuth } from '../../contexts/AuthContext';

import Toast from '../../components/Toast';

import Logo from '../../assets/Logo.png';

import {
  Container,
  SignInForm,
  FormHeader,
  Form,
  FormTexts,
  InputControl,
  Support,
  Brand,
} from './styles';

const SignIn: React.FC = () => {
  const { Login } = useAuth();
  const [visibleToast, setVisibleToast] = useState<boolean>(false);
  const [passwordReveal, setPasswordReveal] = useState<boolean>(false);

  async function handleLogin(event: any): Promise<void> {
    event.preventDefault();
    const { email, password } = event.target.elements;

    try {
      await Login({
        identifier: email.value,
        password: password.value,
      });
    } catch {
      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
      }, 5000);
    }
  }

  return (
    <>
      <Container>
        <SignInForm>
          <FormHeader>
            <Brand>
              <img src={Logo} alt="Logo" />
            </Brand>
          </FormHeader>

          <FormTexts>
            <h1>Entre em sua conta</h1>
            <p>Faça login e gerencie as cotações/solicitações de seguros.</p>
          </FormTexts>

          <Form onSubmit={(event) => handleLogin(event)}>
            <InputControl>
              <small>E-mail</small>
              <input
                type="email"
                placeholder="nome@empresa.com.br"
                name="email"
                required
              />
            </InputControl>

            <InputControl>
              <small>Senha</small>
              <input
                type={passwordReveal ? 'text' : 'password'}
                placeholder="*************"
                name="password"
                required
              />

              <button
                type="button"
                onClick={() => setPasswordReveal(!passwordReveal)}
              >
                {passwordReveal ? 'Esconder' : 'Mostrar'}
              </button>
            </InputControl>

            <button type="submit">Acessar conta</button>
          </Form>

          <Support>
            <small>Não possui acesso? Solicite ao administrador.</small>
          </Support>
        </SignInForm>
      </Container>

      {visibleToast && (
        <Toast title="Verifique os dados solicitados." type="warning" />
      )}
    </>
  );
};

export default SignIn;
