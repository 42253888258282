import styled, { css } from 'styled-components';

interface ContainerProps {
  type?: string;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, type }) => css`
    position: absolute;
    width: auto;
    height: 50px;
    padding: 16px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    right: 0;
    top: 10%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {
      margin-right: 8px;
    }

    ${type === 'warning' && css`
      background-color: ${theme.colors.alert};
      color: #E0AA0B;
    `};

    ${type === 'success' && css`
      background-color: ${theme.colors.success};
      color: #34A853;
    `};

    ${type === 'error' && css`
      background-color: ${theme.colors.error};
      color: #EA4335;
    `};
  `};
`;
