import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import {
  RiArrowRightSLine,
  RiHome6Fill,
  RiUser4Line,
  RiLogoutBoxRFill,
} from 'react-icons/ri';
import { useAuth } from '../../contexts/AuthContext';

import Logo from '../../assets/Logo.png';

import {
  Container,
  Brand,
  MenuItems,
  ToggleButton,
  LogoutButton,
  Item,
} from './styles';

interface SidebarProps {
  toggled: boolean;
  handleToggled: React.MouseEventHandler<HTMLButtonElement>;
}

const Sidebar = ({ toggled = true, handleToggled }: SidebarProps): ReactElement => {
  const history = useHistory();
  const { Logout } = useAuth();

  function handleLogout(): void {
    Logout();
    history.push('/');
  }

  return (
    <Container toggled={toggled}>
      <Brand>
        <img src={Logo} alt="Logo" />
      </Brand>

      <MenuItems>
        <Item exact to="/" activeClassName="active">
          <RiHome6Fill />
          <span>
            Página Inicial
          </span>
        </Item>

        <Item to="/profile" activeClassName="active">
          <RiUser4Line />
          <span>
            Meu perfil
          </span>
        </Item>
      </MenuItems>

      <LogoutButton onClick={() => handleLogout()}>
        <RiLogoutBoxRFill />
        <span>
          Sair
        </span>
      </LogoutButton>

      <ToggleButton onClick={handleToggled}>
        <RiArrowRightSLine />
      </ToggleButton>
    </Container>
  );
};

export default Sidebar;
