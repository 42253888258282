import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import IFrame from '../components/Iframe';

const OtherRoutes: React.FC = () => (
  <BrowserRouter>
    <Route exact path="/" component={SignIn} />
    <Route path="/formularios/seguros" component={IFrame} />
  </BrowserRouter>
);

export default OtherRoutes;
