import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 62.5%; // 1rem = 10px
    height: 100%;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
  }
  
  button, input, small {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }
  
  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;

    @media (min-width: 1430px) {
      background-color: #F8F8F8;
      max-width: 145rem;
    }
  }
  
  body, #root {
    min-height: 100vh;
    position: relative;
    
    display: flex;
    flex-direction: column;
  }
  
  #root {
    height: 100%;
  }
  
  input, div, li, button, a, textarea, select {
    -webkit-tap-highlight-color: transparent; 
  }
  
  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: 0;
  }
  
  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }
`;

export default GlobalStyle;
