import React, { ReactElement } from 'react';
import { RiAlertFill, RiCheckboxCircleFill, RiErrorWarningFill } from 'react-icons/ri';

import {
  Container,
} from './styles';

interface ToastProps {
  title: string;
  type: string;
}

const Toast = ({ title = '', type = '' }: ToastProps): ReactElement => (
  <Container type={type}>
    {type === 'success' && (
      <RiCheckboxCircleFill />
    )}
    {type === 'warning' && (
      <RiAlertFill />
    )}
    {type === 'error' && (
      <RiErrorWarningFill />
    )}
    {title}
  </Container>
);

export default Toast;
