import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Calendar = styled.span`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.2rem;
    color: ${theme.colors.black};
    text-transform: capitalize;

    display: flex;
    align-items: center;

    > svg {
      margin-right: 10px;
      font-size: 1.6rem;
      color: ${theme.colors.primary};
    }

    &::after {
      content: '';
      display: block;
      width: 1.5px;
      height: 12px;
      background-color: ${theme.colors.black};
      opacity: 0.1;
      margin-left: 14px;
    }
  `};
`;

export const UserInfos = styled.small`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 400;
    margin-left: 14px;

    display: flex;
    align-items: center;

    > b {
      margin-left: 4px;
    }

    > svg {
      font-size: 1.2rem;
      margin-left: 8px;
    }
  `};
`;

export const UserImage = styled.div`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    margin-left: 14px;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2.2rem;
    color: ${theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
