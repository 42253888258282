import api from './api';

export default {
  async get() {
    try {
      return ((await api.get('users/me'))).data;
    } catch {
      return console.log('Unable to list a me informations!');
    }
  },

  async update(id: number, informations: any) {
    try {
      return ((await api.put(`users/${id}`, { ...informations }))).data;
    } catch {
      return console.log('Unable to update an user'!);
    }
  },
};
