import styled, { css } from 'styled-components';

interface ContainerProps {
  toggled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, toggled }) => css`
    margin-left: 93px;
    background-color: ${theme.colors.white};
    padding: 46px;
    flex: 1;

    transition: ${theme.transitions.default};

    ${toggled
    && css`
      margin-left: 249px;
    `}
  `};
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    > h1 {
      color: ${theme.colors.black};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const ProfileForm = styled.form`
  ${({ theme }) => css`
    > button {
      background-color: ${theme.colors.primary};
      height: 53px;
      max-width: 107px;
      width: 100%;
      border-radius: 6px;
      color: ${theme.colors.white};
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.7rem;
      border: 1px solid transparent;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.75;
      }
    }
  `};
`;

export const FormRow = styled.div`
  display: flex;

  margin-bottom: 24px;
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    flex: 1;
  
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.textBlack};
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;

      margin-bottom: 14px;
    }

    :not(:last-child) {
      margin-right: 24px;
    }

    > input {
      border: 1px solid rgba(23, 23, 23, 0.2);
      border-radius: 6px;
      height: 53px;
      padding: 18px;
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 500;

      transition: ${theme.transitions.default};

      &::placeholder {
        opacity: 0.3;

        transition: ${theme.transitions.default};
      }

      &:focus {
        color: ${theme.colors.textBlack};
        border-color: ${theme.colors.textBlack};

        &::placeholder {
          opacity: 1;
        }
      }
    }
  `};
`;
