import React, { ReactElement, useState, useRef } from 'react';
import { RiCheckboxCircleLine } from 'react-icons/ri';

import Toast from '../Toast';

import { customersService } from '../../services';

import {
  Container,
  Form,
  FormRow,
  InputControl,
  CheckOption,
  OptionsRow,
  OptionItem,
  Bubble,
  SuccessConfirmation,
  SuccessConfirmationTexts,
} from './styles';

const Navbar = (): ReactElement => {
  const iFrameRef = useRef(null) as any;

  const [goals, setGoals] = useState<string>('possession');
  const [visibleToast, setVisibleToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<string>('');
  const [typeEquipment, setTypeEquipment] = useState<string>('');

  async function handleSubmit(event: any): Promise<void> {
    event.preventDefault();

    const {
      name,
      document,
      phone,
      email,
      serialNumber,
      address,
      cep,
      number,
      complement,
      city,
      uf,
    } = event.target.elements;

    const customerPayload = {
      name: name?.value,
      cpf: document?.value,
      phone: phone?.value,
      email: email?.value,
      typeEquipment,
      serialNumber: serialNumber?.value,
      purpose: goals,
      address: {
        cep: cep?.value,
        address: address?.value,
        number: number?.value,
        complement: complement?.value,
        city: city?.value,
        uf: uf?.value,
      },
    };

    setVisibleToast(true);

    try {
      await customersService.post(customerPayload);

      setToastType('success');
      setToastMessage('Formulário enviado com sucesso.');

      event.target.reset();
    } catch {
      setToastType('error');
      setToastMessage('Não foi possível executar essa ação, tente novamente.');
    }
  }

  function sendHeight(): any {
    const height = iFrameRef?.current?.clientHeight;

    if (height) {
      window.parent.postMessage({ height }, '*');
    }
  }

  window.addEventListener('load', sendHeight);

  return (
    <>
      <Container ref={iFrameRef}>
        <h1>Preencha os dados abaixo:</h1>

        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormRow>
            <InputControl>
              <small>Nome</small>
              <input
                type="text"
                placeholder="Digite seu nome"
                name="name"
                required
              />
            </InputControl>

            <InputControl>
              <small>CPF/CNPJ</small>
              <input
                type="text"
                placeholder="Ex: 123.456.789-00 ou 12.345.678/0001-00"
                name="document"
                required
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>E-mail</small>
              <input
                type="email"
                placeholder="Digite seu e-mail"
                name="email"
                required
              />
            </InputControl>

            <InputControl>
              <small>Telefone</small>
              <input
                type="phone"
                placeholder="Ex: (51) 9 9999 9999"
                name="phone"
                required
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>CEP</small>
              <input
                type="text"
                placeholder="Ex: 91010-001"
                name="cep"
                required
              />
            </InputControl>

            <InputControl>
              <small>Endereço do local de utilização</small>
              <input
                type="text"
                placeholder="Rua Dois Irmãos"
                name="address"
                required
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>Número</small>
              <input
                type="number"
                placeholder="Ex: 455"
                name="number"
                required
              />
            </InputControl>

            <InputControl>
              <small>Complemento</small>
              <input
                type="text"
                placeholder="Box, torre, apto, unidade"
                name="complement"
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>Cidade</small>
              <input
                type="text"
                placeholder="Ex: Porto Alegre"
                name="city"
                required
              />
            </InputControl>

            <InputControl>
              <small>Estado</small>
              <input
                type="text"
                placeholder="Ex: Rio Grande do Sul"
                name="uf"
                required
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>Tipo de equipamento</small>
              <select
                placeholder="Selecione seu equipamento"
                name="typeEquipment"
                required
                defaultValue=""
                value={typeEquipment}
                onChange={(e) => setTypeEquipment(e?.target?.value)}
              >
                <option value="" disabled>
                  Selecione seu equipamento
                </option>
                <option value="ultramed">Ultramed</option>
                <option value="criodermis">Criodermis</option>
                <option value="hakon">Hakon</option>
              </select>
            </InputControl>

            <InputControl>
              <small>Número de série do equipamento</small>
              <input
                type="text"
                placeholder="Ex: 120393DSAUH218"
                name="serialNumber"
                required
              />
            </InputControl>
          </FormRow>

          <CheckOption>
            <small>Finalidade</small>
            <b>Marque apenas uma opção abaixo:</b>

            <OptionsRow>
              <OptionItem active={goals === 'possession'}>
                É de minha posse
                <Bubble onClick={() => setGoals('possession')}>
                  <span />
                </Bubble>
              </OptionItem>

              <OptionItem active={goals === 'third'}>
                Aluguel para terceiro
                <Bubble onClick={() => setGoals('third')}>
                  <span />
                </Bubble>
              </OptionItem>
            </OptionsRow>
          </CheckOption>

          <button type="submit">Enviar</button>
        </Form>
      </Container>

      {visibleToast && (
      <SuccessConfirmation>
        <SuccessConfirmationTexts>
          <RiCheckboxCircleLine />
          <h2>Dados enviados com sucesso!</h2>
          <p>
            Seus dados foram enviados com sucesso para nossa base de dados e
            seguiremos em contato através dos dados que você acabou de preencher.
            <b>Para visualizar o formulário novamente, atualize a página.</b>
          </p>
        </SuccessConfirmationTexts>
      </SuccessConfirmation>
      )}
    </>
  );
};

export default Navbar;
