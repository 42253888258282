import React, { useEffect, useState } from 'react';
// import { RiSearchLine, RiEyeLine, RiArrowDownSLine } from 'react-icons/ri';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
// import Card from '../../components/Card';
import { meService } from '../../services';

import {
  Container,
  Header,
  ProfileForm,
  FormRow,
  InputControl,
} from './styles';

const Profile: React.FC = () => {
  const [toggled, setToggled] = useState<boolean>(true);
  const [meInformations, setMeInformations] = useState([] as any);

  useEffect(() => {
    getInformations();
  }, []);

  async function getInformations(): Promise<void> {
    setMeInformations(await meService.get());
  }

  async function handleSubmit(event: any): Promise<void> {
    event.preventDefault();
    const {
      name, lastName, email, phone,
    } = event.target.elements;

    const meInformationsPayload = {
      name: name?.value,
      lastName: lastName?.value,
      email: email?.value,
      phone: phone?.value,
    } as any;

    meService.update(meInformations?.id, meInformationsPayload);
  }

  return (
    <>
      <Sidebar toggled={toggled} handleToggled={() => setToggled(!toggled)} />

      <Container toggled={toggled}>
        <Header>
          <h1>Meu perfil</h1>

          <Navbar />
        </Header>

        <ProfileForm onSubmit={(event) => handleSubmit(event)}>
          <FormRow>
            <InputControl>
              <small>Nome</small>
              <input
                type="text"
                placeholder="Nome"
                defaultValue={meInformations?.name || ''}
                name="name"
              />
            </InputControl>

            <InputControl>
              <small>Sobrenome</small>
              <input
                type="text"
                placeholder="Sobrenome"
                defaultValue={meInformations?.lastName || ''}
                name="lastName"
              />
            </InputControl>
          </FormRow>

          <FormRow>
            <InputControl>
              <small>E-mail</small>
              <input
                type="email"
                placeholder="E-mail"
                defaultValue={meInformations?.email || ''}
                name="email"
              />
            </InputControl>

            <InputControl>
              <small>Telefone</small>
              <input
                type="text"
                placeholder="Telefone"
                defaultValue={meInformations?.phone || ''}
                name="phone"
              />
            </InputControl>
          </FormRow>

          <button type="submit">Salvar</button>
        </ProfileForm>
      </Container>
    </>
  );
};

export default Profile;
