import React, { useEffect, useState, useMemo } from 'react';

import {
  RiSearchLine,
  RiEyeLine,
  RiCloseLine,
  RiArrowDownSLine,
} from 'react-icons/ri';

import { customersService } from '../../services';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import {
  Container,
  Header,
  SearchInput,
  // InfoCardsList,
  // MainTable,
  // MainTableHeader,
  Table,
  TableHeader,
  TableHeaderItem,
  TableBody,
  TableBodyRow,
  TablePagination,
  TableBodyItem,
  TablePaginationNumber,
  StatusTag,
  EmptyMessage,
  CustomerModal,
  CustomerModalContent,
  CustomerModalHeader,
  CustomerModalBody,
  CustomerModalFooter,
  CustomerUpdateInfos,
  CustomerStatus,
  DropdownTags,
  CustomerModalRow,
  DetailInputControl,
  InputContent,
  SelectOption,
  Bubble,
  SelectOptionRow,
  SelectOptions,
  PaginationItem,
  DropdownStatusMenu,
  DropdownStatusItem,
  DropdownStatusSection,
  DropdownIcon,
} from './styles';
// import Card from '../../components/Card';

const Home: React.FC = () => {
  const [toggled, setToggled] = useState<boolean>(true);
  const [customers, setCustomers] = useState([]) as any;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredCustomers, setFilteredCustomers] = useState([]) as any;
  const [visibleDetailModal, setVisibleDetailModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState([] as any);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 6 as number;
  const [visibleStatusDropdown, setVisibleStatusDropdown] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('pending');
  const translatedStatus = {
    paid: 'Emitido',
    cotation: 'Em cotação',
    pending: 'Pendente',
  } as any;
  const [selectedCustomerID, setSelectedCustomerID] = useState<any>(null);

  useEffect(() => {
    filterCustomers();
  }, [searchQuery]);

  useEffect(() => {
    getCustomers();
  }, []);

  async function getCustomers(): Promise<any> {
    const responseData = (await customersService.get()) as any;

    if (responseData?.length <= 6) {
      setCurrentPage(1);
    }

    setCustomers(responseData);
    setFilteredCustomers(responseData);
  }

  const purposeOptions = {
    third: 'Aluguel para terceiro',
    possession: 'Posse do cliente',
  } as any;

  function filterCustomers(): void {
    const filteredData = customers?.filter(
      (customer: any) => (customer?.attributes?.name ? customer?.attributes?.name : '')
        .toLowerCase()
        .includes(searchQuery)
        || (customer?.attributes?.typeEquipment
          ? customer?.attributes?.typeEquipment
          : ''
        )
          .toLowerCase()
          .includes(searchQuery)
        || (customer?.attributes?.createdAt ? customer?.attributes?.createdAt : '')
          .toLowerCase()
          .includes(searchQuery)
        || (purposeOptions[customer?.attributes?.purpose]
          ? purposeOptions[customer?.attributes?.purpose]
          : ''
        )
          .toLowerCase()
          .includes(searchQuery),
    );

    setFilteredCustomers(filteredData);
  }

  const customersData = useMemo(() => {
    const computedUsers = filteredCustomers;

    return computedUsers?.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage,
    );
  }, [filteredCustomers, currentPage]);

  const [totalPages, setTotalPages] = useState(0);
  const total = filteredCustomers?.length;

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage));
    }
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i += 1) {
      pages.push(
        <PaginationItem
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </PaginationItem>,
      );
    }

    return pages;
  }, [totalPages, currentPage]);

  async function handleStatusUpdate(): Promise<void> {
    (await customersService.update(selectedCustomerID, selectedStatus)) as any;
    getCustomers();
  }

  useEffect(() => {
    if (selectedCustomerID) {
      handleStatusUpdate();
    }
  }, [selectedStatus]);

  return (
    <>
      <Sidebar toggled={toggled} handleToggled={() => setToggled(!toggled)} />

      <Container toggled={toggled}>
        <Header>
          <h1>Página Inicial</h1>

          <Navbar />
        </Header>

        <SearchInput>
          <RiSearchLine />
          <input
            type="text"
            placeholder="Buscar operação"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchInput>

        <Table>
          <TableHeader>
            <TableHeaderItem>Cliente</TableHeaderItem>
            <TableHeaderItem>Tipo de equipamento</TableHeaderItem>
            <TableHeaderItem>Status</TableHeaderItem>
            <TableHeaderItem>Data</TableHeaderItem>
            <TableHeaderItem>Finalidade</TableHeaderItem>
            <TableHeaderItem>Ações</TableHeaderItem>
          </TableHeader>
          <TableBody>
            {filteredCustomers?.length
              ? customersData?.map((customer: any) => (
                <TableBodyRow key={customer?.id}>
                  <TableBodyItem>
                    {customer?.attributes?.name || ''}
                  </TableBodyItem>
                  <TableBodyItem>
                    {customer?.attributes?.typeEquipment || ''}
                  </TableBodyItem>
                  <TableBodyItem>
                    <StatusTag
                      status={customer?.attributes?.status || 'pending'}
                    >
                      {translatedStatus[customer?.attributes?.status]
                          ?? translatedStatus.pending}
                    </StatusTag>
                  </TableBodyItem>
                  <TableBodyItem>
                    {new Date(customer?.attributes?.createdAt).toLocaleString(
                      'pt-br',
                    ) || ''}
                  </TableBodyItem>
                  <TableBodyItem>
                    {purposeOptions[customer?.attributes?.purpose] || ''}
                  </TableBodyItem>
                  <TableBodyItem>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedStatus(
                          customer?.attributes?.status || 'pending',
                        );
                        setSelectedCustomerID(customer?.id);
                        setSelectedCustomer(customer?.attributes);
                        setVisibleDetailModal(true);
                      }}
                    >
                      <RiEyeLine />
                    </button>
                  </TableBodyItem>
                </TableBodyRow>
              ))
              : ((
                <EmptyMessage>
                  <h2>Nenhum cliente foi criado ainda.</h2>
                </EmptyMessage>
                ) as any)}
          </TableBody>
          <TablePagination>
            <small>
              Exibindo
              {' '}
              {itemsPerPage}
              {' '}
              resultados de
              {' '}
              {filteredCustomers?.length}
            </small>

            <TablePaginationNumber>{paginationItems}</TablePaginationNumber>
          </TablePagination>
        </Table>
      </Container>

      {visibleDetailModal && (
        <CustomerModal>
          <CustomerModalContent>
            <CustomerModalHeader>
              {selectedCustomer?.name || ''}
              <RiCloseLine onClick={() => setVisibleDetailModal(false)} />
            </CustomerModalHeader>

            <CustomerModalBody>
              <CustomerModalRow>
                <DetailInputControl>
                  <small>Nome</small>
                  <InputContent>{selectedCustomer?.name || ''}</InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>CPF/CNPJ</small>
                  <InputContent>{selectedCustomer?.cpf || ''}</InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>E-mail</small>
                  <InputContent>{selectedCustomer?.email || ''}</InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Telefone</small>
                  <InputContent>{selectedCustomer?.phone || ''}</InputContent>
                </DetailInputControl>
              </CustomerModalRow>

              <CustomerModalRow>
                <DetailInputControl>
                  <small>Endereço</small>
                  <InputContent>
                    {selectedCustomer?.address?.address || ''}
                  </InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Número</small>
                  <InputContent>
                    {selectedCustomer?.address?.number || ''}
                  </InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Complemento</small>
                  <InputContent>
                    {selectedCustomer?.address?.complement || ''}
                  </InputContent>
                </DetailInputControl>
              </CustomerModalRow>

              <CustomerModalRow>
                <DetailInputControl>
                  <small>CEP</small>
                  <InputContent>
                    {selectedCustomer?.address?.cep || ''}
                  </InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Cidade</small>
                  <InputContent>
                    {selectedCustomer?.address?.city || ''}
                  </InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Estado</small>
                  <InputContent>
                    {selectedCustomer?.address?.uf || ''}
                  </InputContent>
                </DetailInputControl>
              </CustomerModalRow>

              <CustomerModalRow>
                <DetailInputControl>
                  <small>Tipo de equipamento</small>
                  <InputContent>
                    {selectedCustomer?.typeEquipment || ''}
                  </InputContent>
                </DetailInputControl>

                <DetailInputControl>
                  <small>Número de série do equipamento</small>
                  <InputContent>
                    {selectedCustomer?.serialNumber || ''}
                  </InputContent>
                </DetailInputControl>
              </CustomerModalRow>

              <CustomerModalRow>
                <SelectOptions>
                  <small>Finalidade</small>
                  <SelectOptionRow>
                    <SelectOption
                      active={selectedCustomer?.purpose === 'possession'}
                    >
                      Posse do cliente
                      <Bubble>
                        <span />
                      </Bubble>
                    </SelectOption>

                    <SelectOption
                      active={selectedCustomer?.purpose === 'third'}
                    >
                      Aluguel para terceiro
                      <Bubble>
                        <span />
                      </Bubble>
                    </SelectOption>
                  </SelectOptionRow>
                </SelectOptions>
              </CustomerModalRow>
            </CustomerModalBody>

            <CustomerModalFooter>
              <CustomerUpdateInfos>
                <small>
                  Criado em
                  {' '}
                  {new Date(selectedCustomer?.createdAt).toLocaleString(
                    'pt-br',
                  ) || ''}
                </small>
                <b>
                  Atualizado em
                  {' '}
                  {new Date(selectedCustomer?.updatedAt).toLocaleString(
                    'pt-br',
                  ) || ''}
                </b>
              </CustomerUpdateInfos>

              <CustomerStatus>
                <small>Status:</small>
                <DropdownTags
                  onClick={() => setVisibleStatusDropdown(!visibleStatusDropdown)}
                >
                  <StatusTag status={selectedStatus}>
                    {translatedStatus[selectedStatus]}
                  </StatusTag>

                  <DropdownIcon rotate={visibleStatusDropdown ?? false}>
                    <RiArrowDownSLine />
                  </DropdownIcon>

                  {visibleStatusDropdown && (
                    <DropdownStatusMenu>
                      <DropdownStatusSection>
                        <DropdownStatusItem
                          onClick={() => setSelectedStatus('cotation')}
                          status="cotation"
                        >
                          Em cotação
                        </DropdownStatusItem>
                      </DropdownStatusSection>

                      <DropdownStatusSection
                        onClick={() => setSelectedStatus('paid')}
                      >
                        <DropdownStatusItem status="paid">
                          Emitido
                        </DropdownStatusItem>
                      </DropdownStatusSection>
                    </DropdownStatusMenu>
                  )}
                </DropdownTags>
              </CustomerStatus>
            </CustomerModalFooter>
          </CustomerModalContent>
        </CustomerModal>
      )}
    </>
  );
};

export default Home;
