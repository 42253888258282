import api from './api';

export default {
  async get(): Promise<void> {
    try {
      return ((await api.get('customers?populate=address')).data).data;
    } catch {
      return console.log('Unable to list a customers list!');
    }
  },

  async post(customer: any): Promise<void> {
    try {
      delete api.defaults.headers.common.Authorization;

      return ((await api.post('customers?populate=address', {
        data: { ...customer },
      })).data).data;
    } catch {
      throw new Error('Unable to create a customer list');
    }
  },

  async update(id: number, status: string): Promise<void> {
    try {
      return ((await api.put(`customers/${id}`, {
        data: { status },
      })).data).data;
    } catch {
      throw new Error('Unable to update a customer list');
    }
  },
};
