import styled, { css } from 'styled-components';

interface ContainerProps {
  toggled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, toggled }) => css`
    margin-left: 93px;
    background-color: ${theme.colors.white};
    padding: 46px;
    flex: 1;

    transition: ${theme.transitions.default};

    ${toggled
    && css`
      margin-left: 249px;
    `}
  `};
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    > h1 {
      color: ${theme.colors.black};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const InfoCardsList = styled.div`
  margin: 26px 0;

  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`;

export const MainTable = styled.div`
  margin-top: 26px;
`;

export const MainTableHeader = styled.div`
  ${({ theme }) => css`
    background: rgba(20, 18, 30, 0.05);
    border-radius: 4px;
    height: 64px;
    color: ${theme.colors.black};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    align-items: center;

    > input {
      height: 46px;
      width: 100%;
      padding: 12px 18px 12px 44px;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 2.2rem;
      border: 1px solid transparent;
      border-radius: 4px;
      background-color: ${theme.colors.backgroundBlue};

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.black};
        opacity: 0.3;

        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: rgba(24, 58, 119, 0.3);

        &::placeholder {
          opacity: 1;
        }
      }
    }

    > svg {
      position: absolute;
      left: 18px;
      font-size: 1.6rem;
      color: ${theme.colors.primary};
    }
  `};
`;

export const Table = styled.div`
  margin-top: 14px;
`;

export const TableFilters = styled.div`
  ${({ theme }) => css`
    margin-bottom: 14px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      color: ${theme.colors.textBlack};
      opacity: 0.5;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 1.6rem;
      max-width: 243px;
      width: 100%;
    }
  `};
`;

export const TableHeader = styled.div`
  background-color: rgba(24, 58, 119, 0.05);
  border-radius: 4px;
  height: 64px;
  padding: 24px;
  margin-bottom: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableHeaderItem = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textBlack};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;
    max-width: 135px;
    width: 100%;

    &:last-child {
      text-align: center;
    }

    &:last-child {
      max-width: 70px;
    }
  `};
`;

export const TableBody = styled.div``;

export const TableBodyRow = styled.div`
  margin-bottom: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableBodyItem = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textBlack};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 24px;
    width: 100%;
    max-height: 54px;

    display: flex;
    align-items: center;

    &:last-child {
      justify-content: center;
    }

    &:last-child {
      max-width: 120px;
    }

    :nth-child(2) {
      text-transform: capitalize;
    }

    > button {
      border: 1px solid rgba(23, 23, 23, 0.1);
      border-radius: 4px;
      width: 30px;
      height: 30px;
      background-color: transparent;
      color: ${theme.colors.primary};

      transition: ${theme.transitions.default};

      &:hover {
        border-color: ${theme.colors.primary};
      }
    }
  `};
`;

export const TablePagination = styled.div`
  ${({ theme }) => css`
    margin-top: 28px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > small {
      color: ${theme.colors.textBlack};
      opacity: 0.5;
      font-size: 1.1rem;
      line-height: 1.2rem;
      font-weight: 400;
    }
  `};
`;

export const StatusTag = styled.div<{ status?: string }>`
  ${({ theme, status }) => css`
    border-radius: 2px;
    height: 24px;
    padding: 6px 10px;
    color: ${theme.colors.textBlack};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    ${status === 'paid'
    && css`
      background: rgba(0, 249, 144, 0.2);
    `};

    ${status === 'cotation'
    && css`
      background: rgba(0, 20, 290, 0.2);
    `};

    ${status === 'pending'
    && css`
      background: rgba(249, 224, 0, 0.2);
    `};
  `};
`;

export const FiltersRow = styled.div`
  margin-left: 12px;
  flex: 1;

  display: flex;
  justify-content: flex-end;
`;

export const FilterSelect = styled.div`
  ${({ theme }) => css`
    border: 1px solid rgba(23, 23, 23, 0.1);
    border-radius: 4px;
    max-width: 200px;
    width: 100%;
    padding: 12px 18px;
    height: 52px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > svg {
      color: ${theme.colors.textBlack};
      font-size: 1.6rem;
    }

    :not(:last-child) {
      margin-right: 12px;
    }
  `};
`;

export const FilterSelectTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    color: ${theme.colors.textBlack};
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;

    > small {
      opacity: 0.5;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1rem;
      margin-bottom: 2px;
    }
  `};
`;

export const FilterSelectItems = styled.div`
  position: absolute;
  display: none;
`;

export const CustomerModal = styled.div`
  background-color: rgba(23, 23, 23, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 50px 0;

  display: flex;
  align-items: center;
`;

export const CustomerModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 948px;
    width: 100%;
    background-color: ${theme.colors.white};
    margin: 12px auto;
    border-radius: 6px;
    padding: 32px;

    display: flex;
    flex-direction: column;
  `};
`;

export const CustomerModalHeader = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 18px;
    color: ${theme.colors.textBlack};
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > svg {
      font-size: 2.4rem;
      cursor: pointer;
    }
  `};
`;

export const CustomerModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 18px;
`;

export const CustomerUpdateInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    color: ${theme.colors.textBlack};
    opacity: 0, 5;

    > small {
      color: ${theme.colors.textBlack};
      opacity: 0.5;
      font-weight: 400;
      font-size: ;
    }

    > b {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      opacity: 0.5;
      color: ${theme.colors.textBlack};
      margin-top: 4px;
    }
  `};
`;

export const CustomerModalBody = styled.div`
  flex: 1 0;
  padding: 18px 0;
`;

export const CustomerStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    cursor: pointer;

    > small {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1rem;
      margin-right: 16px;
      color: ${theme.colors.textBlack};
    }
  `};
`;

export const DropdownTags = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid rgba(23, 23, 23, 0.1);
  border-radius: 4px;
  height: 40px;
  padding: 8px 14px 8px 8px;
  width: 150px;

  position: relative;

  ${StatusTag} {
    margin-right: 38px;
  }
`;

export const EmptyMessage = styled.div`
  ${({ theme }) => css`
    border: 1px solid rgba(24, 58, 119, 0.05);
    padding: 24px;
    min-height: 120px;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    > h2 {
      font-weight: 500;
      color: ${theme.colors.textBlack};
      font-size: 1.2rem;
    }
  `};
`;

export const CustomerModalRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const DetailInputControl = styled.div`
  ${({ theme }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.textBlack};
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 14px;
    }

    :not(:last-child) {
      margin-right: 16px;
    }
  `};
`;

export const InputContent = styled.div`
  border: 1px solid rgba(23, 23, 23, 0.2);
  border-radius: 6px;
  height: 53px;
  padding: 18px;
  background: #f2f2f2;
  color: #171717;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* text-transform: capitalize; */

  display: flex;
  align-items: center;
`;

export const Bubble = styled.span`
  ${({ theme }) => css`
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid ${theme.colors.textBlack};
    margin-left: auto;
    border-radius: 50%;
    cursor: pointer;

    position: relative;

    transition: ${theme.transitions.default};

    > span {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: transparent;
      margin-left: auto;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `};
`;

export const SelectOption = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    background: #f2f2f2;
    border: 1px solid ${theme.colors.textBlack};
    border-radius: 6px;
    height: 53px;
    flex: 1;
    padding: 18px;
    color: ${theme.colors.textBlack};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.2;

    display: flex;
    align-items: center;

    :not(:last-child) {
      margin-right: 14px;
    }

    ${active
    && css`
      opacity: 1;

      ${Bubble} {
        transition: ${theme.transitions.default};

        > span {
          background-color: ${theme.colors.textBlack};
        }
      }
    `}
  `};
`;

export const SelectOptionRow = styled.div`
  display: flex;
  width: 100%;
`;

export const SelectOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    width: 100%;

    > small {
      color: ${theme.colors.textBlack};
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 14px;
    }
  `};
`;

export const PaginationItem = styled.span<{ active?: boolean }>`
  ${({ theme, active }) => css`
    color: #171717;
    opacity: 0.5;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 16px;
    cursor: pointer;

    width: 16px;

    display: flex;

    ${active
    && css`
      opacity: 1;
    `}
  `};
`;

export const TablePaginationNumber = styled.div`
  display: flex;
  justify-content: center;
`;

export const DropdownStatusMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 0;

  width: 100%;
  height: 40px;
  border-radius: 0 0 4px 4px;
`;

export const DropdownStatusItem = styled.div<{ status?: string }>`
  ${({ status }) => css`
    height: 24px;
    border-radius: 2px;
    cursor: pointer;
    max-width: 78px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${status === 'paid'
    && css`
      background: rgba(0, 249, 144, 0.2);
    `};

    ${status === 'cotation'
    && css`
      background: rgba(0, 20, 290, 0.2);
    `};

    ${status === 'pending'
    && css`
      background: rgba(249, 224, 0, 0.2);
    `};
  `};
`;

export const DropdownStatusSection = styled.div`
  padding: 8px;
  border-radius: 0 0 4px 4px;
  background-color: #FFF;

  :not(:last-child) {
    border-bottom: 1px solid rgba(23, 23, 23, 0.1);
    border-radius: 0;
  }
`;

export const MainDropdownStatus = styled.div``;

export const DropdownIcon = styled.div<{ rotate?: boolean }>`
  ${({ rotate }) => css`
    transition: all 0.2s ease-out; 
    margin-left: auto;
    
    ${rotate
      && css`
        transform: rotate(180deg);
    `};
  `};
`;
