const defaultTheme = {
  colors: {
    primary: '#183A77',
    black: '#14121E',
    black90: '#2C2A35',
    black80: '#43414B',
    black70: '#5B5962',
    black60: '#727178',
    black50: '#89888F',
    black40: '#A1A0A5',
    black30: '#B9B8BB',
    black20: '#D0D0D2',
    black10: '#E7E7E8',
    black05: '#F3F3F4',
    textBlack: '#171717',
    background: '#F8F8F8',
    backgroundButton: '#E5E5E5',
    backgroundBlue: '#F6FAFD',
    white: '#FFFFFF',
    alert: 'rgba(224, 170, 11, 0.1)',
    error: 'rgba(234, 67, 53, 0.1)',
    success: 'rgba(52, 168, 83, 0.1)',
    softAlert: '#FCF9ED',
    softError: '#FCEDED',
    info: '#4EABE0',
    softInfo: '#EDF7FC',
  },
  transitions: {
    default: 'all 0.4s ease-out',
  },
};

export default defaultTheme;
