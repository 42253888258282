import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    /* padding: 32px; */
    min-height: 100vh;
    border-radius: 6px;
    background-color: ${theme.colors.white};
    /* max-width: 790px; */
    width: 100%;
    margin: 0 auto;
    /* opacity: 0; */

    @media (max-width: 768px) {
      padding: 0;
    }
  `};
`;

export const Form = styled.form`
  ${({ theme }) => css`
    margin-top: 32px;

    > button {
      background-color: ${theme.colors.primary};
      width: 100%;
      height: 53px;
      border-radius: 6px;
      color: ${theme.colors.white};
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.7rem;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.75;
      }
    }
  `};
`;

export const FormRow = styled.div`
  display: flex;

  margin-bottom: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }

    > small {
      color: ${theme.colors.textBlack};
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;

      margin-bottom: 14px;
    }

    :not(:last-child) {
      margin-right: 24px;

      @media (max-width: 768px) {
        margin-right: unset;
      }
    }

    > select {
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 1.8rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
    }

    > input, select {
      border: 1px solid rgba(23, 23, 23, 0.2);
      border-radius: 6px;
      height: 53px;
      padding: 18px;
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 500;
      background-color: transparent;

      transition: ${theme.transitions.default};

      &::placeholder {
        opacity: 0.3;

        transition: ${theme.transitions.default};
      }

      &:focus {
        color: ${theme.colors.textBlack};
        border-color: ${theme.colors.textBlack};

        &::placeholder {
          opacity: 1;
        }
      }
    }
  `};
`;

export const CheckOption = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.textBlack};
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;
      margin-bottom: 14px;
    }

    > b {
      color: ${theme.colors.textBlack};
      opacity: 0.3;
      font-size: 1.2rem;
      font-weight: 400;
      font-style: italic;
    }
  `};
`;

export const OptionsRow = styled.div`
  margin: 14px 0;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const OptionItem = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    flex: 1;
    height: 53px;
    border-radius: 6px;
    padding: 18px;
    border: 1px solid rgba(23, 23, 23, 0.2);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${theme.colors.textBlack};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    :first-child {
      margin-right: 16px;

      @media (max-width: 768px) {
        margin-right: unset;
        margin-bottom: 14px;
      }
    }

    ${active
    && css`
      border-color: ${theme.colors.textBlack};

      transition: ${theme.transitions.default};

      ${Bubble} {
        transition: ${theme.transitions.default};

        > span {
          background-color: ${theme.colors.textBlack};
        }
      }
    `}
  `};
`;

export const Bubble = styled.span`
  ${({ theme }) => css`
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid ${theme.colors.textBlack};
    margin-left: auto;
    border-radius: 50%;
    cursor: pointer;

    position: relative;

    transition: ${theme.transitions.default};

    > span {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: transparent;
      margin-left: auto;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `};
`;

export const SuccessConfirmation = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;  
    background-color: ${theme.colors.white};
  `};
`;

export const SuccessConfirmationTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: 100%;
    max-width: 443px;
    margin: 0 auto;
    text-align: center;

    > svg {
      font-size: 3.2rem;
    }

    > h2 {
      margin: 16px 0;
      color: ${theme.colors.textBlack};
      font-size: 2.8rem;
      line-height: 3.4rem;
      font-weight: 600;
    }

    > p {
      opacity: 0.8;
      color: ${theme.colors.textBlack};
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.8rem;

      display: flex;
      flex-direction: column;
    }
  `};
`;
